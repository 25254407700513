import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ModuleType, defaultModule } from "../typings/ModuleType";

const initialState: ModuleType = defaultModule;

const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    createModule(state, action) {
      state = action.payload;
    },

    updateModule(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key as keyof ModuleType] = action.payload[key];
      });
    },

    deleteModule(state) {
      state = {};
    },
  },
});

export const { createModule, updateModule, deleteModule } = moduleSlice.actions;

export const moduleState = (state: RootState) => state.module;

export default moduleSlice.reducer;
