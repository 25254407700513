import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

export const checkAdmin = async (uid: string): Promise<boolean> => {
  try {
    const checkAdminFunction = httpsCallable(functions, "checkAdmin");
    const result = await checkAdminFunction({ uid });
    return result.data as boolean;
  } catch (error) {
    console.error("Error checking admin:", error);
    throw error;
  }
};
