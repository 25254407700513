import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51NQhyTLNpf3sEXwVqmgZHqGPoHVCTOCng7c3z7YnG2a4jCptMw4DQNedVRKw55x50up4JFA924hVzgLHIMVlYOgJ00c1UQRH4B"
    );
  }
  return stripePromise;
};
export default initializeStripe;
