import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Account from "./pages/Account";
import FinishLogin from "./pages/FinishLogin";
import Login from "./pages/Login";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import { authState } from "./redux/authSlice";
import Pricing from "./pages/Pricing";
import Home from "./pages/Home";
import Modules from "./pages/Modules";
import Module from "./pages/Module";
import { useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase.config";
import { updateProject } from "./redux/projectSlice";
import { ProjectType } from "./typings/ProjectType";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Features from "./pages/Features";
import Feature from "./pages/Feature";

export default function App() {
  const state = useSelector(authState);
  const dispatch = useDispatch();

  useEffect(() => {
    const getSelectedStory = async () => {
      const unsubscribe = onSnapshot(
        doc(db, `users/${state.uid}/projects`, state.selectedProject!),
        async (snapshot) => {
          if (snapshot.exists()) {
            dispatch(updateProject(snapshot.data() as ProjectType));
          }
        }
      );
      return () => unsubscribe();
    };
    if (state.uid && state.selectedProject) {
      getSelectedStory();
    }
  }, [dispatch, state.selectedProject, state.uid]);

  return (
    <BrowserRouter>
      <div className="flex flex-col w-screen min-h-screen bg-[#e6e6e6]">
        <Header />
        <div className="flex flex-col flex-grow w-full h-full max-w-5xl p-5 mx-auto">
          <Routes>
            <Route
              path="/projects"
              element={state.uid ? <Projects /> : <Navigate to="/login" />}
            />

            <Route
              path="/projects/:projectId"
              element={state.uid ? <Project /> : <Navigate to="/login" />}
            />

            <Route
              path="/projects/:projectId/modules"
              element={state.uid ? <Modules /> : <Navigate to="/login" />}
            />
            <Route
              path="/projects/:projectId/modules/:moduleId"
              element={state.uid ? <Module /> : <Navigate to="/login" />}
            />

            <Route
              path="/projects/:projectId/features"
              element={state.uid ? <Features /> : <Navigate to="/login" />}
            />
            <Route
              path="/projects/:projectId/features/:featureId"
              element={state.uid ? <Feature /> : <Navigate to="/login" />}
            />

            <Route
              path="/account"
              element={state.uid ? <Account /> : <Navigate to="/login" />}
            />
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/finish-login" element={<FinishLogin />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

          <Footer />
        </div>
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}
