import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ModuleType, defaultModule } from "../typings/ModuleType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useProject } from "../hooks/useProject";
import DocumentForm from "../components/DocumentForm";

export default function Module() {
  const state = useSelector(authState);
  const { projectId, moduleId } = useParams<{
    projectId?: string;
    moduleId?: string;
  }>();
  const [project, prompt, module] = useProject(projectId, moduleId);

  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (module && module.name && prompt && project) {
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt: prompt,
          userPrompt: `Write a 70-word summary of the ${module.name} section of the ${project.name} business plan, with no titles or headings.`,
          relatedFields: ["name", "summary", "description"],
        },
        {
          field: "description",
          name: "Description",
          type: "textarea",
          minRows: 4,
          systemPrompt: prompt,
          userPrompt: `Write a detailed ${module.name} section for the ${project.name} business plan, with no titles or headings. Do not end with "in conclusion", "in summary", "in essence" or the like. Instead, just conclude the section like a great business plan writer would.`,
          relatedFields: ["name", "summary", "description", "promptHelp"],
        },

        {
          field: "promptHelp",
          name: "Prompt Help",
          type: "textarea",
          minRows: 3,
        },

        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [prompt, project, module]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {project && (
        <div className="text-2xl font-semibold">
          Project: {project.name} / {state.selectedModuleName}
        </div>
      )}
      {state.uid && moduleId && projectId && fieldDefinitions && (
        <DocumentForm<ModuleType>
          initialValues={defaultModule}
          fieldDefinitions={fieldDefinitions}
          title={`Selected Module`}
          collectionName={`users/${state.uid}/projects/${projectId}/modules/`}
          docId={moduleId}
        />
      )}
    </div>
  );
}
