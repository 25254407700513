import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authState } from "../redux/authSlice";
import { createCheckoutSession } from "../stripe/createCheckoutSessions";
import { getPortalLink } from "../stripe/getPortalLink";

export default function Pricing() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const state = useSelector(authState);
  return (
    <div className="flex-1 bg-white">
      <div className="flex flex-col flex-1 max-w-3xl p-5 mx-auto space-y-5">
        <h1 className="px-3 py-2 text-3xl font-bold text-center border rounded-md">
          Pricing
        </h1>
        <main className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          <section className="flex flex-col p-5 space-y-3 border rounded-md">
            <h3 className="text-xl">Free Starter</h3>
            <p>
              Begin your entrepreneurial journey with StartupPlanner.ai for
              free! Discover how we can revolutionize your business planning
              approach.
            </p>
            <ul className="px-4 list-disc">
              <li>Basic business planning insights</li>
              <li>Glimpse into AI-assisted business strategy creation</li>
            </ul>
          </section>
          <section className="flex flex-col p-5 space-y-3 border rounded-md">
            <h3 className="text-xl">Full Access</h3>
            <p>
              Enhance your business planning experience with comprehensive
              access to StartupPlanner.ai. From business idea brainstorming to
              full business plan creation, we've got it all covered.
            </p>
            <ul className="px-4 list-disc">
              <li>Unlimited business planning insights</li>
              <li>Dynamic business plan generation</li>
              <li>Access to the full suite of business planning tools</li>
            </ul>
            <h3>Just $49.99 Per Month!</h3>
          </section>
        </main>
        <div>
          <h3 className="text-xl text-center mx-7">
            Transform your business planning style and bring your ideas to life
            with StartupPlanner.ai. Start your journey today and create the
            startup you've always dreamt of.
          </h3>
        </div>

        {state.premium ? (
          <button
            className="mx-auto btn btn-secondary"
            onClick={() => {
              toast.loading("Connecting Stripe...");
              getPortalLink();
            }}
          >
            View Subscription
          </button>
        ) : state.uid ? (
          <button
            className="mx-auto btn btn-primary"
            onClick={() => {
              toast.loading("Connecting Stripe...");
              if (state.uid) createCheckoutSession(state.uid);
            }}
          >
            Upgrade to Full Access
          </button>
        ) : (
          <button
            className="mx-auto btn btn-primary"
            onClick={() => navigate("/login")}
          >
            Start Free Starter Today
          </button>
        )}
      </div>
    </div>
  );
}
