import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ProjectType, defaultProject } from "../typings/ProjectType";

const initialState: ProjectType = defaultProject;

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    createProject(state, action) {
      state = action.payload;
    },

    updateProject(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key as keyof ProjectType] = action.payload[key];
      });
    },

    deleteProject(state) {
      state = {};
    },
  },
});

export const { createProject, updateProject, deleteProject } =
  projectSlice.actions;

export const projectState = (state: RootState) => state.project;

export default projectSlice.reducer;
