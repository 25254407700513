import { useSelector } from "react-redux";
import { ProjectType, defaultProject } from "../typings/ProjectType";
import { authState } from "../redux/authSlice";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useProject } from "../hooks/useProject";
import DocumentForm from "../components/DocumentForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Project() {
  const state = useSelector(authState);
  const { projectId } = useParams<{ projectId?: string }>();
  const [project, prompt] = useProject(projectId, state.selectedModule || "");
  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (prompt && project) {
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Write a summary of ${project.name} with sufficient detail to guide subsequent OpenAI api completion prompts. Answer in 100 words or less with no titles or headings.`,
          relatedFields: ["writingStyle", "targetAudience"],
        },
        {
          field: "logline",
          name: "Logline",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Write an elevator pitch for ${project.name}. Answer in 30 words or less with no titles or headings.`,
          relatedFields: ["writingStyle", "targetAudience"],
        },
        {
          field: "synopsis",
          name: "Synopsis",
          type: "textarea",
          minRows: 6,
          systemPrompt: prompt,
          userPrompt: `Write a detailed synopsis of ${project.name} with no titles or headings. Do not end with "In conclusion", "in summary", "in essence" or the like. Instead, just conclude the section like a great business plan writer would.`,
          relatedFields: ["promptHelp", "writingStyle", "targetAudience"],
        },

        {
          field: "promptHelp",
          name: "Prompt Help",
          type: "textarea",
          minRows: 3,
        },

        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },

        {
          field: "writingStyle",
          name: "Writing Style",
          type: "text",
          minRows: 1,
        },
        {
          field: "targetAudience",
          name: "Target Audience",
          type: "text",
          minRows: 1,
        },
        { field: "author", name: "Author", type: "text", minRows: 1 },
      ]);
    }
  }, [prompt, project]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {state.uid && fieldDefinitions && projectId && (
        <DocumentForm<ProjectType>
          initialValues={defaultProject}
          fieldDefinitions={fieldDefinitions}
          title={`Project`}
          collectionName={`users/${state.uid}/projects`}
          docId={projectId}
        />
      )}
    </div>
  );
}
