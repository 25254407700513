import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useProject } from "../hooks/useProject";
import CollectionList from "../components/CollectionList";
import { defaultModules } from "../data/defaultModules";

export default function Modules() {
  const state = useSelector(authState);
  const { projectId } = useParams<{ projectId?: string }>();
  const [project] = useProject(projectId, state.selectedModule || "");

  if (!state.uid) return <div>No user</div>;
  if (!projectId) return <div>No project</div>;
  if (!project) return <div>Loading</div>;

  const generateMultipleItems = async () => {
    return defaultModules;
  };

  return (
    <CollectionList
      collectionName={`users/${state.uid}/projects/${projectId}/modules`}
      title="Modules"
      baseUrl={`/projects/${projectId}/modules/`}
      generateMultipleItems={generateMultipleItems}
    />
  );
}
