import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAT1ZaCkBT2bQwJaPK13dvhtrfNQdQp9jc",
  authDomain: "startupplanner.ai",
  projectId: "startupplannerai",
  storageBucket: "startupplannerai.appspot.com",
  messagingSenderId: "135738498650",
  appId: "1:135738498650:web:25c1ca00a8372f31b24263",
  measurementId: "G-BHZ1V0G6W9",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { db, storage, auth, functions, analytics };
