import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authState,
  selectedFeature,
  selectedModule,
  selectedProject,
} from "../redux/authSlice";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.config";
import { ProjectType } from "../typings/ProjectType";
import { ModuleType } from "../typings/ModuleType";
import { FeatureType } from "../typings/FeatureType";

export const useProject = (
  projectId: string | undefined,
  moduleId?: string | undefined,
  featureId?: string | undefined
): [
  ProjectType | null,
  string | null,
  ModuleType | null,
  FeatureType | null
] => {
  const state = useSelector(authState);
  const dispatch = useDispatch();
  const [project, setProject] = useState<ProjectType | null>(null);
  const [module, setModule] = useState<ModuleType | null>(null);
  const [feature, setFeature] = useState<FeatureType | null>(null);
  const [prompt, setPrompt] = useState<string | null>(null);

  console.log("useSelectProject", projectId, "module:", moduleId);
  useEffect(() => {
    if (projectId && state.uid) {
      const docRef = doc(db, `users/${state.uid}/projects`, projectId);

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setProject(docSnap.data() as ProjectType);
          dispatch(selectedProject(projectId));
        } else {
          console.log(`No such project!`);
          setProject(null);
        }
      });

      // Clean up subscription on unmount
      return () => unsubscribe();
    }
  }, [projectId, state.uid, dispatch]);

  useEffect(() => {
    if (moduleId && projectId && state.uid) {
      const docRef = doc(
        db,
        `users/${state.uid}/projects/${projectId}/modules`,
        moduleId
      );

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setModule(docSnap.data() as ModuleType);
          dispatch(
            selectedModule({ id: moduleId, name: docSnap.data()?.name })
          );
        } else {
          console.log(`No such module!`);
          setModule(null);
        }
      });

      // Clean up subscription on unmount
      return () => unsubscribe();
    }
  }, [projectId, state.uid, dispatch, moduleId]);

  useEffect(() => {
    if (featureId && projectId && state.uid) {
      const docRef = doc(
        db,
        `users/${state.uid}/projects/${projectId}/features`,
        featureId
      );

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setFeature(docSnap.data() as FeatureType);
          dispatch(
            selectedFeature({ id: featureId, name: docSnap.data()?.name })
          );
        } else {
          console.log(`No such feature!`);
          setFeature(null);
        }
      });

      // Clean up subscription on unmount
      return () => unsubscribe();
    }
  }, [projectId, state.uid, dispatch, featureId]);

  useEffect(() => {
    if (project) {
      let prompt = `You are writing elements for the following business plan:\n\n`;
      if (project.name) prompt += "Company Name: " + project.name + "\n\n";
      if (project.summary) prompt += "Summary: " + project.summary + "\n\n";
      if (project.synopsis) prompt += "Synopsis: " + project.synopsis + "\n\n";
      if (project.writingStyle)
        prompt += "Writing style: " + project.writingStyle;

      setPrompt(prompt);
    }
  }, [project]);

  return [project, prompt, module, feature]; // return the selected project
};
