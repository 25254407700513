import { FeatureType } from "../typings/FeatureType";

export const defaultFeatures: FeatureType[] = [
  {
    name: "User Account Management",
    order: 1,
    promptHelp:
      "Implement secure user registration, profile creation, account pages for settings and progress, subscription management, and affiliate tracking system.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Web App Front End (React)",
    order: 2,
    promptHelp:
      "Design and implement onboarding, course access, meditation library, daily news access, voice generation, localization, and accessibility features.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Mobile App Front End (React Native)",
    order: 3,
    promptHelp:
      "All features from the Web App, with additional functionality for offline access, Android-specific features, and iOS-specific features.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Course Content Management",
    order: 4,
    promptHelp:
      "Implement structure for seven 14-day course modules, with specific daily content, meditation libraries, and relevant daily news content from external feeds.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Assessments and Mindset Scorecard",
    order: 5,
    promptHelp:
      "Implement initial and progress assessments, visualize results on a mindset scorecard, provide user feedback.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Progress Tracking and Gamification",
    order: 6,
    promptHelp:
      "Track user's course progress, implement badges and rewards system, possibly include leaderboards.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Sequencing and Content Unlocking",
    order: 7,
    promptHelp:
      "Manage the order of course content access, unlock meditations and news content after the completion of a course module.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Notifications",
    order: 8,
    promptHelp:
      "Implement system for push notifications and email notifications for new content, course modules, and achievements.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Back End (Firebase and Google Cloud)",
    order: 9,
    promptHelp:
      "Manage user data, course content, subscription status, external data, and offline data sync.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Content Management System (CMS)",
    order: 10,
    promptHelp:
      "Upload and manage course content, meditation files, and generate daily news content from external feeds.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Admin Dashboard",
    order: 11,
    promptHelp:
      "Manage user accounts, course content, and user subscription status.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Marketing Pages",
    order: 12,
    promptHelp:
      "Design and implement landing pages, pricing page, and a section for testimonials and reviews.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Payment Processing",
    order: 13,
    promptHelp:
      "Integrate with a payment gateway, manage secure storage of payment details, handle failed payments.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Integration with External Services",
    order: 14,
    promptHelp:
      "Connect with external data feeds, AI language model, and payment gateways.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "AI-Assisted Tools",
    order: 15,
    promptHelp:
      "Design and implement AI-assisted tools for passion identification, purpose development, and moonshot development.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Data Privacy and Compliance",
    order: 16,
    promptHelp:
      "Manage user consent, comply with data protection regulations, and secure user data.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Feedback and Reporting",
    order: 17,
    promptHelp:
      "Implement system for user feedback and issue tracking and resolution.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "User Experience Testing and Iteration",
    order: 18,
    promptHelp:
      "Perform regular UX testing to identify pain points and areas for improvement. Utilize analytics to monitor user behavior and performance. Iterate on designs and features based on feedback.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "SEO and Online Marketing",
    order: 19,
    promptHelp:
      "Implement SEO best practices and monitor performance. Plan and execute online marketing campaigns.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Performance Monitoring and Optimization",
    order: 20,
    promptHelp:
      "Implement performance monitoring and error tracking systems. Regularly review performance and optimize as necessary for smooth user experience.",
    summary: "",
    description: "",
    notes: "",
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
];
