import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import CollectionList from "../components/CollectionList";

export default function Projects() {
  const state = useSelector(authState);

  if (!state.uid) return <div>No user</div>;

  return (
    <CollectionList
      collectionName={`users/${state.uid}/projects`}
      title="Projects"
      baseUrl={`/projects/`}
    />
  );
}
