import { ModuleType } from "../typings/ModuleType";

export const defaultModules: ModuleType[] = [
  {
    name: "Executive Summary",
    summary: "",
    description: "",
    promptHelp:
      "This is a brief summary of the business plan that should pique the reader's interest. It should include the company's name, mission, and vision, as well as a summary of the plan's highlights.",
    notes: "",
    order: 1,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },

  {
    name: "Company Description",
    summary: "",
    description: "",
    promptHelp:
      "This section gives an in-depth look at what the company does. It should cover the problems the company solves, the target market, and what sets the business apart from others.",
    notes: "",
    order: 2,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Market Analysis",
    summary: "",
    description: "",
    promptHelp:
      "Here, users should detail the state of their industry, market size, expected growth, and their competitors. It's a chance to show understanding of the market landscape and their place within it.",
    notes: "",
    order: 3,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Organization and Management",
    summary: "",
    description: "",
    promptHelp:
      "This section should describe the company's legal structure (LLC, corporation, etc.), organizational structure, and the management team. If there's an advisory board, include it too.",
    notes: "",
    order: 4,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Product Line or Services",
    summary: "",
    description: "",
    promptHelp:
      "This part should describe what the company is selling or what services it's offering. It should cover the lifecycle of the product or service, intellectual property, and research and development activities (if applicable).",
    notes: "",
    order: 5,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Marketing and Sales Strategy",
    summary: "",
    description: "",
    promptHelp:
      "Here, users need to describe how they'll attract and retain customers. This section should outline the marketing plan, sales strategy, and methods for scaling customer acquisition.",
    notes: "",
    order: 6,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Funding Request",
    summary: "",
    description: "",
    promptHelp:
      "If the startup is seeking funding, this section should outline the amount of funding needed over the next five years, how the funds will be used, and the preferred funding type (debt, equity, etc.).",
    notes: "",
    order: 7,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Financial Projections",
    summary: "",
    description: "",
    promptHelp:
      "To persuade investors that the business is stable, this section should include income statements, balance sheets, and cash flow statements for the next 3-5 years. Any graphs or charts used to highlight financial data could be especially helpful.",
    notes: "",
    order: 8,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
  {
    name: "Appendix",
    summary: "",
    description: "",
    promptHelp:
      "This is an optional section that can include any additional supporting documents like legal documents, contracts, permits, patents, or credit histories.",
    notes: "",
    order: 9,
    wordCount: 0,
    createdSeconds: 0,
    updatedSeconds: 0,
    locked: false,
  },
];

export const defaultStructure = `Executive Summary: This is a brief summary of the business plan that should pique the reader's interest. It should include the company's name, mission, and vision, as well as a summary of the plan's highlights.

Company Description: This section gives an in-depth look at what the company does. It should cover the problems the company solves, the target market, and what sets the business apart from others.

Market Analysis: Here, users should detail the state of their industry, market size, expected growth, and their competitors. It's a chance to show understanding of the market landscape and their place within it.

Organization and Management: This section should describe the company's legal structure (LLC, corporation, etc.), organizational structure, and the management team. If there's an advisory board, include it too.

Product Line or Services: This part should describe what the company is selling or what services it's offering. It should cover the lifecycle of the product or service, intellectual property, and research and development activities (if applicable).

Marketing and Sales Strategy: Here, users need to describe how they'll attract and retain customers. This section should outline the marketing plan, sales strategy, and methods for scaling customer acquisition.

Funding Request: If the startup is seeking funding, this section should outline the amount of funding needed over the next five years, how the funds will be used, and the preferred funding type (debt, equity, etc.).

Financial Projections: To persuade investors that the business is stable, this section should include income statements, balance sheets, and cash flow statements for the next 3-5 years. Any graphs or charts used to highlight financial data could be especially helpful.

Appendix: This is an optional section that can include any additional supporting documents like legal documents, contracts, permits, patents, or credit histories.`;
