export type FeatureType = {
  //Editable Properties
  name?: string;
  summary?: string;
  description?: string;
  promptHelp?: string;
  notes?: string;

  //Read-Only Properties
  order?: number;
  wordCount?: number;
  createdSeconds?: number;
  updatedSeconds?: number;
  locked?: boolean;

  //ID Properties
  id?: string;
};

export const defaultFeature: FeatureType = {
  name: "",
  summary: "",
  description: "",
  promptHelp: "",
  notes: "",

  order: 0,
  wordCount: 0,
  createdSeconds: 0,
  updatedSeconds: 0,
  locked: false,
};
