export type ProjectType = {
  //Editable Properties
  name?: string;
  summary?: string;
  logline?: string;
  synopsis?: string;
  promptHelp?: string;
  notes?: string;

  targetAudience?: string;
  author?: string;
  writingStyle?: string;

  //Read-Only Properties
  order?: number;
  wordCount?: number;
  createdSeconds?: number;
  updatedSeconds?: number;
  locked?: boolean;

  // ID Properties
  id?: string; // The unique identifier for the project.
};

export const defaultProject: ProjectType = {
  name: "",
  summary: "",
  logline: "",
  synopsis: "",
  promptHelp: "",
  notes: "",

  targetAudience: "",
  author: "",
  writingStyle: "",

  order: 0,
  wordCount: 0,
  createdSeconds: 0,
  updatedSeconds: 0,
  locked: false,
  id: "",
};
