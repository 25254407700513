import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authState } from "../redux/authSlice";
import logo from "../assets/startupplanner_black_t.png";
import LoginSmall from "./LoginSmall";

export default function Home() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const state = useSelector(authState);
  return (
    <div className="flex flex-col flex-1 p-5 space-y-5 bg-white">
      <img className="object-contain w-full h-48" src={logo} alt="logo" />

      <div className="max-w-lg mx-auto text-center">
        <h1 className="mb-4 text-4xl font-bold">StartupPlanner.ai</h1>

        <h2 className="mb-6 text-2xl font-semibold">
          Embark on Your Entrepreneurial Journey
        </h2>

        <div className="px-4">
          <h3 className="mb-3 text-xl font-medium">
            Simplify Your Business Planning Process
          </h3>
          <p className="mb-4">
            StartupPlanner.ai acts as your personal startup coach. Our AI-driven
            platform helps you brainstorm business ideas based on your
            strengths, interests, passion and market ideas, then aids you in
            developing a comprehensive business plan.
          </p>

          <h3 className="mb-3 text-xl font-medium">
            Revolutionize Your Business Strategy
          </h3>
          <p>
            Experience a systematic, step-by-step approach to business planning
            that simplifies your strategic process and helps you create a robust
            and compelling plan for your startup.
          </p>
        </div>
      </div>
      <div className="pb-7">
        {!state.uid ? (
          <LoginSmall />
        ) : (
          <button
            className="mx-auto btn btn-primary"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/projects");
            }}
          >
            Start Planning
          </button>
        )}
      </div>
    </div>
  );
}
