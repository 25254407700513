import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useProject } from "../hooks/useProject";
import DocumentForm from "../components/DocumentForm";
import { FeatureType, defaultFeature } from "../typings/FeatureType";

export default function Feature() {
  const state = useSelector(authState);
  const { projectId, featureId } = useParams<{
    projectId?: string;
    featureId?: string;
  }>();
  const [project, prompt, module, feature] = useProject(
    projectId,
    "",
    featureId
  );

  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (feature && feature.name && prompt && project) {
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt: prompt,
          userPrompt: `Write a 50-word summary of the ${feature.name} section of the ${project.name} functional product specification, with no titles or headings.`,
          relatedFields: ["summary", "description", "promptHelp"],
        },
        {
          field: "description",
          name: "Description",
          type: "textarea",
          minRows: 4,
          systemPrompt: prompt,
          userPrompt: `Write a detailed requirements list of the ${feature.name} section of the functional product specification for the ${project.name} product.`,
          relatedFields: ["summary", "description", "promptHelp"],
        },

        {
          field: "promptHelp",
          name: "Prompt Help",
          type: "textarea",
          minRows: 3,
        },

        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [prompt, project, feature]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {project && (
        <div className="text-2xl font-semibold">
          Project: {project.name} / {state.selectedFeatureName}
        </div>
      )}
      {state.uid && featureId && projectId && fieldDefinitions && (
        <DocumentForm<FeatureType>
          initialValues={defaultFeature}
          fieldDefinitions={fieldDefinitions}
          title={`Selected Feature`}
          collectionName={`users/${state.uid}/projects/${projectId}/features/`}
          docId={featureId}
        />
      )}
    </div>
  );
}
