import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const menuItems = [
    { label: "StartupPlanner.ai", link: "/" },
    { label: "Account", link: "/account" },
    { label: "Pricing", link: "/pricing" },
    { label: "Privacy", link: "/privacy" },
    { label: "Terms", link: "/terms" },
  ];

  const menuDisplay = menuItems.map((item, index) => (
    <div
      key={index}
      onClick={() => {
        window.scrollTo(0, 0);
        navigate(item.link);
      }}
      className="cursor-pointer hover:underline hover:underline-offset-4 hover:text-gray-700"
    >
      {item.label}
    </div>
  ));

  return (
    <div className="flex flex-wrap items-center justify-center max-w-5xl px-5 pt-5 pb-2 mx-auto mt-auto space-x-2 text-gray-500">
      <div>&copy;2023</div>
      {menuDisplay}
    </div>
  );
}
